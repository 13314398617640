import { LineClampProps } from 'components/Toolkit/LineClamp/LineClamp.typed';
import { Container } from 'components/Toolkit/LineClamp/LineClamp.styled';

const LineClamp = ({ children, forwardedAs, ...props }: LineClampProps) => {
  return (
    <Container as={forwardedAs} {...props}>
      {children}
    </Container>
  );
};

export { LineClamp };
