import styled from 'styled-components';

const Container = styled.span<{ lines: number }>`
  ${({ lines }) => `
  @supports (-webkit-line-clamp: ${lines}) {
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: ${lines};
    -webkit-box-orient: vertical;
  }`}

  overflow: hidden;
  word-break: break-word;
`;

export { Container };
