import styled from 'styled-components';

import { TimeRewindOutlineIc } from '@dsch/dd-icons';

import { InfoTitle } from 'components/Toolkit/InfoTitle/InfoTitle';
import { Card } from 'components/ToolkitV2/CardV2/Card';

type StyledProps = { isDisabled?: boolean };

const Container = styled.div<StyledProps>`
  box-shadow: ${({ isDisabled, theme }) =>
    isDisabled ? 'none' : theme.shadow.CONTAINER};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
`;

const StyledCard = styled(Card)<StyledProps>`
  background-color: ${({ theme, isDisabled }) =>
    isDisabled ? theme.colors.GREY_LIGHTER : theme.colors.WHITE};

  &:hover {
    background-color: ${({ theme }) => theme.colors.GREY_LIGHTER};
  }
`;

const Header = styled(InfoTitle)`
  margin: ${({ theme }) => theme.spacing.S8} 0;
`;

const Icon = styled(TimeRewindOutlineIc)`
  color: ${({ theme }) => theme.colors.GREY_DARKER};
`;

export { Container, StyledCard, Header, Icon };
