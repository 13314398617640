import { LineClamp } from 'components/Toolkit/LineClamp/LineClamp';

import { WithLink } from 'components/ToolkitV2/CardV2/enhancements/WithLink/WithLink';

import { ORIENTATION } from 'components/ToolkitV2/CardV2/Card.typed';

import type { TLastSearch } from 'components/PageWrappers/SharedComponents/LastSearchCard/types';

import {
  Container,
  StyledCard,
  Header,
  Icon,
} from 'components/PageWrappers/SharedComponents/LastSearchCard/LastSearchCard.styled';

import { accessibleOnClick } from 'utils/Accessibility';

type LastSearchesCardProps = {
  lastSearchCallback?: () => void;
  lastSearchData?: TLastSearch;
  className?: string;
  lastSearchDataTrackingValue?: string;
  shallow?: boolean;
};

const LastSearchCard = (props: LastSearchesCardProps) => {
  const {
    lastSearchCallback,
    lastSearchData,
    className,
    lastSearchDataTrackingValue,
    shallow,
  } = props;

  return (
    <Container
      className={className}
      isDisabled={!lastSearchData}
      {...(lastSearchCallback
        ? { ...accessibleOnClick(lastSearchCallback) }
        : {})}
    >
      <WithLink
        href={lastSearchData?.searchLink}
        aria-disabled={!lastSearchData}
        shallow={shallow}
        dataTracking={lastSearchDataTrackingValue}
      >
        <StyledCard
          header={
            <Header
              title="Your last search"
              description={
                lastSearchData?.terms && (
                  <LineClamp lines={1}>{lastSearchData?.terms}</LineClamp>
                )
              }
              icon={<Icon height={24} width={24} />}
              variant="XS"
              disabled={!lastSearchData}
            />
          }
          orientationSmall={ORIENTATION.HORIZONTAL}
          paddingSmall="S8"
        />
      </WithLink>
    </Container>
  );
};

export { LastSearchCard };
